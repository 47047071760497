exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aires-acondicionados-bgh-tsx": () => import("./../../../src/pages/aires-acondicionados-bgh.tsx" /* webpackChunkName: "component---src-pages-aires-acondicionados-bgh-tsx" */),
  "component---src-pages-aires-acondicionados-carrier-tsx": () => import("./../../../src/pages/aires-acondicionados-carrier.tsx" /* webpackChunkName: "component---src-pages-aires-acondicionados-carrier-tsx" */),
  "component---src-pages-aires-acondicionados-lg-tsx": () => import("./../../../src/pages/aires-acondicionados-lg.tsx" /* webpackChunkName: "component---src-pages-aires-acondicionados-lg-tsx" */),
  "component---src-pages-aires-acondicionados-philco-tsx": () => import("./../../../src/pages/aires-acondicionados-philco.tsx" /* webpackChunkName: "component---src-pages-aires-acondicionados-philco-tsx" */),
  "component---src-pages-aires-acondicionados-samsung-tsx": () => import("./../../../src/pages/aires-acondicionados-samsung.tsx" /* webpackChunkName: "component---src-pages-aires-acondicionados-samsung-tsx" */),
  "component---src-pages-aires-acondicionados-surrey-tsx": () => import("./../../../src/pages/aires-acondicionados-surrey.tsx" /* webpackChunkName: "component---src-pages-aires-acondicionados-surrey-tsx" */),
  "component---src-pages-aires-acondicionados-tsx": () => import("./../../../src/pages/aires-acondicionados.tsx" /* webpackChunkName: "component---src-pages-aires-acondicionados-tsx" */),
  "component---src-pages-aurora-tsx": () => import("./../../../src/pages/aurora.tsx" /* webpackChunkName: "component---src-pages-aurora-tsx" */),
  "component---src-pages-bgh-tsx": () => import("./../../../src/pages/bgh.tsx" /* webpackChunkName: "component---src-pages-bgh-tsx" */),
  "component---src-pages-carrier-tsx": () => import("./../../../src/pages/carrier.tsx" /* webpackChunkName: "component---src-pages-carrier-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-drean-tsx": () => import("./../../../src/pages/drean.tsx" /* webpackChunkName: "component---src-pages-drean-tsx" */),
  "component---src-pages-electrolux-tsx": () => import("./../../../src/pages/electrolux.tsx" /* webpackChunkName: "component---src-pages-electrolux-tsx" */),
  "component---src-pages-eslabon-de-lujo-tsx": () => import("./../../../src/pages/eslabon-de-lujo.tsx" /* webpackChunkName: "component---src-pages-eslabon-de-lujo-tsx" */),
  "component---src-pages-gafa-tsx": () => import("./../../../src/pages/gafa.tsx" /* webpackChunkName: "component---src-pages-gafa-tsx" */),
  "component---src-pages-general-electric-tsx": () => import("./../../../src/pages/general-electric.tsx" /* webpackChunkName: "component---src-pages-general-electric-tsx" */),
  "component---src-pages-heladeras-electrolux-tsx": () => import("./../../../src/pages/heladeras-electrolux.tsx" /* webpackChunkName: "component---src-pages-heladeras-electrolux-tsx" */),
  "component---src-pages-heladeras-eslabon-de-lujo-tsx": () => import("./../../../src/pages/heladeras-eslabon-de-lujo.tsx" /* webpackChunkName: "component---src-pages-heladeras-eslabon-de-lujo-tsx" */),
  "component---src-pages-heladeras-gafa-tsx": () => import("./../../../src/pages/heladeras-gafa.tsx" /* webpackChunkName: "component---src-pages-heladeras-gafa-tsx" */),
  "component---src-pages-heladeras-general-electric-tsx": () => import("./../../../src/pages/heladeras-general-electric.tsx" /* webpackChunkName: "component---src-pages-heladeras-general-electric-tsx" */),
  "component---src-pages-heladeras-kohinoor-tsx": () => import("./../../../src/pages/heladeras-kohinoor.tsx" /* webpackChunkName: "component---src-pages-heladeras-kohinoor-tsx" */),
  "component---src-pages-heladeras-lg-tsx": () => import("./../../../src/pages/heladeras-lg.tsx" /* webpackChunkName: "component---src-pages-heladeras-lg-tsx" */),
  "component---src-pages-heladeras-patrick-tsx": () => import("./../../../src/pages/heladeras-patrick.tsx" /* webpackChunkName: "component---src-pages-heladeras-patrick-tsx" */),
  "component---src-pages-heladeras-samsung-tsx": () => import("./../../../src/pages/heladeras-samsung.tsx" /* webpackChunkName: "component---src-pages-heladeras-samsung-tsx" */),
  "component---src-pages-heladeras-tsx": () => import("./../../../src/pages/heladeras.tsx" /* webpackChunkName: "component---src-pages-heladeras-tsx" */),
  "component---src-pages-heladeras-whirlpool-tsx": () => import("./../../../src/pages/heladeras-whirlpool.tsx" /* webpackChunkName: "component---src-pages-heladeras-whirlpool-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kohinoor-tsx": () => import("./../../../src/pages/kohinoor.tsx" /* webpackChunkName: "component---src-pages-kohinoor-tsx" */),
  "component---src-pages-lavarropas-aurora-tsx": () => import("./../../../src/pages/lavarropas-aurora.tsx" /* webpackChunkName: "component---src-pages-lavarropas-aurora-tsx" */),
  "component---src-pages-lavarropas-drean-tsx": () => import("./../../../src/pages/lavarropas-drean.tsx" /* webpackChunkName: "component---src-pages-lavarropas-drean-tsx" */),
  "component---src-pages-lavarropas-electrolux-tsx": () => import("./../../../src/pages/lavarropas-electrolux.tsx" /* webpackChunkName: "component---src-pages-lavarropas-electrolux-tsx" */),
  "component---src-pages-lavarropas-gafa-tsx": () => import("./../../../src/pages/lavarropas-gafa.tsx" /* webpackChunkName: "component---src-pages-lavarropas-gafa-tsx" */),
  "component---src-pages-lavarropas-general-electric-tsx": () => import("./../../../src/pages/lavarropas-general-electric.tsx" /* webpackChunkName: "component---src-pages-lavarropas-general-electric-tsx" */),
  "component---src-pages-lavarropas-lg-tsx": () => import("./../../../src/pages/lavarropas-lg.tsx" /* webpackChunkName: "component---src-pages-lavarropas-lg-tsx" */),
  "component---src-pages-lavarropas-patrick-tsx": () => import("./../../../src/pages/lavarropas-patrick.tsx" /* webpackChunkName: "component---src-pages-lavarropas-patrick-tsx" */),
  "component---src-pages-lavarropas-samsung-tsx": () => import("./../../../src/pages/lavarropas-samsung.tsx" /* webpackChunkName: "component---src-pages-lavarropas-samsung-tsx" */),
  "component---src-pages-lavarropas-tsx": () => import("./../../../src/pages/lavarropas.tsx" /* webpackChunkName: "component---src-pages-lavarropas-tsx" */),
  "component---src-pages-lavarropas-whirlpool-tsx": () => import("./../../../src/pages/lavarropas-whirlpool.tsx" /* webpackChunkName: "component---src-pages-lavarropas-whirlpool-tsx" */),
  "component---src-pages-lg-tsx": () => import("./../../../src/pages/lg.tsx" /* webpackChunkName: "component---src-pages-lg-tsx" */),
  "component---src-pages-patrick-tsx": () => import("./../../../src/pages/patrick.tsx" /* webpackChunkName: "component---src-pages-patrick-tsx" */),
  "component---src-pages-philco-tsx": () => import("./../../../src/pages/philco.tsx" /* webpackChunkName: "component---src-pages-philco-tsx" */),
  "component---src-pages-samsung-tsx": () => import("./../../../src/pages/samsung.tsx" /* webpackChunkName: "component---src-pages-samsung-tsx" */),
  "component---src-pages-surrey-tsx": () => import("./../../../src/pages/surrey.tsx" /* webpackChunkName: "component---src-pages-surrey-tsx" */),
  "component---src-pages-whirlpool-tsx": () => import("./../../../src/pages/whirlpool.tsx" /* webpackChunkName: "component---src-pages-whirlpool-tsx" */)
}

